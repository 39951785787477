import cx from "classnames";
import React from "react";
import { PublicationShortname } from "src/app/auth/serviceIdentifier";

import styles from "./index.module.scss";

type Props = {
  children: string | JSX.Element;
  text: string;
  position?: "top" | "bottom" | "left" | "right";
  theme: PublicationShortname | null;
};

function Tooltip({ children, text, position = "top", theme }: Props) {
  const getTooltipPosition = () => {
    switch (position) {
      case "bottom":
        return styles.bottom;
      case "left":
        return styles.left;
      case "right":
        return styles.right;
      default:
        return styles.top;
    }
  };

  return (
    <div className={cx(styles.tooltip)}>
      {children}
      <span
        className={cx([styles.tooltiptext, getTooltipPosition()])}
        data-theme={theme}
      >
        {text}
      </span>
    </div>
  );
}

export default Tooltip;
