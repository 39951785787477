// Refer to MySPH Lightbox Documentation (WIP) for list of available service identifiers

export enum PublicationShortname {
  ST = "st",
  ZB = "zb",
  BH = "bh",
  TM = "tm",
  SM = "sm",
  BT = "bt",
  TABLA = "tabla",
  DEFAULT = "default",
}

export const NO_AUTH_PUBLICATION: PublicationShortname[] = [
  PublicationShortname.TABLA,
];

type NoAuthPublication = (typeof NO_AUTH_PUBLICATION)[number];

type AuthRequiredPublication = Exclude<PublicationShortname, NoAuthPublication>;

// List of supported languages as per epaper and mysph lightbox
type SupportedLang = "en" | "zh" | "ms" | "ta";

const PUB_LANG = {
  [PublicationShortname.ST]: "en",
  [PublicationShortname.ZB]: "zh",
  [PublicationShortname.BT]: "en",
  [PublicationShortname.BH]: "ms",
  [PublicationShortname.TM]: "ta",
  [PublicationShortname.TABLA]: "en",
  [PublicationShortname.SM]: "zh",
  [PublicationShortname.DEFAULT]: "en",
} as const;

export function getLanguageByPubId(pubId: PublicationShortname): SupportedLang {
  return PUB_LANG[pubId];
}

const SERVICE_IDENTIFIER: Record<AuthRequiredPublication, string> = {
  [PublicationShortname.ST]: "stp",
  [PublicationShortname.ZB]: "zbs_ep",
  [PublicationShortname.BT]: "bts_ep",
  [PublicationShortname.BH]: "bh_online_ep",
  [PublicationShortname.TM]: "cir_tm_online",
  [PublicationShortname.SM]: "cir_sm_online",
  [PublicationShortname.DEFAULT]: "stp", // Temporary solution until we can conclude on default pubID
} as const;

export function getServiceFlagsByPubsId(
  pubsId: AuthRequiredPublication,
): string {
  return SERVICE_IDENTIFIER[pubsId];
}

export function getPubIdBySvc(svc: string): PublicationShortname | undefined {
  const keys: PublicationShortname[] = Object.keys(
    SERVICE_IDENTIFIER,
  ) as PublicationShortname[]; // Cast needed here since Object.keys doesn't retain types
  const pubId = keys.find((key) => SERVICE_IDENTIFIER[key] === svc);
  return pubId;
}

const SUBSCRIBE_NOW_URL: Record<AuthRequiredPublication, string> = {
  [PublicationShortname.ST]: "https://subscribe.sph.com.sg/publications-st/",
  [PublicationShortname.ZB]: "https://subscribe.sph.com.sg/zh/publications-zb/",
  [PublicationShortname.BT]: "https://subscribe.sph.com.sg/publications-bt/",
  [PublicationShortname.BH]: "https://subscribe.sph.com.sg/publications-bh/",
  [PublicationShortname.TM]: "https://subscribe.sph.com.sg/publications-tm/",
  [PublicationShortname.SM]: "https://subscribe.sph.com.sg/zh/publications-sm/",
  [PublicationShortname.DEFAULT]:
    "https://subscribe.sph.com.sg/publications-st/",
} as const;

export function getSubscribeNowUrlByPubId(
  pubsId: AuthRequiredPublication,
): string {
  return SUBSCRIBE_NOW_URL[pubsId];
}
