import { MySphLightboxHelper } from "@sphtech/web2-core/auth";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import Header from "@src/app/components/Header";
import TileView from "@src/app/components/TileView";
import cx from "classnames";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "src/app/AppContext";
import { saveCookiePath } from "src/app/auth/cookiePath";
import {
  getServiceFlagsByPubsId,
  NO_AUTH_PUBLICATION,
  PublicationShortname,
} from "src/app/auth/serviceIdentifier";
import { DrawerContainer } from "src/app/components/DrawerContainer/index";
import IssueContainer from "src/app/components/IssuesContainer";
import MultiTitle from "src/app/components/MultiTitle";
import { OnboardingModal } from "src/app/components/OnboardingModal";
import { MIN_DESKTOP_WIDTH } from "src/app/data";
import useDateFormat from "src/app/hooks/useDateFormat";
import { Actions } from "src/app/reducer";
import { Language } from "src/app/types/Data.d";
import { getScreenOrientation } from "src/app/utils/getScreenOrientation";
import {
  EP_GA_EVENT_NAME,
  GAClickAction,
  getGAAuthenticationStatus,
  getGAPublicationName,
  getTrackerDateString,
  PAGE_NAME,
  useEpGTMDispatch,
} from "src/trackers/googleAnalytics";
import { DEFAULT_VISITOR_CONTEXT } from "src/trackers/viewerContext";

import assertEnum from "../../../../.web2/web2-helpers/assertEnum";
import { fetchArchives, getIssueById, TArchive, TIssue } from "./api";
import styles from "./Home.module.scss";
import { HomePagePayload } from "./Home.server";

const YMD_FORMAT = "YYYYMMDD";

enum ISSUE_GA_CLICK_LABEL {
  IMAGE_CLICK = "start reading front page",
  START_READING_BTN = "start reading",
  CLOSE_ONBOARDING_MODAL = "onboarding",
  INFO_BUTTON = "info",
  CALENDAR = "calendar",
}

export const COOKIE_KEY_PUBS_ID = "sph_pubsId";
export const COOKIE_ISSUE_ID = "sph_issueId";

type HomePageContextType = {
  toggleDrawer: () => void;
  selectedDate: Date;
  setSelectedDate: (_: Date | string) => void;
  calendarData: Record<string, TArchive[]> | null;
  isDesktopView: boolean;
  issues: Partial<TArchive>[];
};
export const HomePageContext = React.createContext<HomePageContextType>({
  toggleDrawer: () => {},
  setSelectedDate: () => {},
  calendarData: null,
  isDesktopView: true,
  selectedDate: new Date(),
  issues: [],
});

// this CustomContext is difficult to understand
type RouteContext = CustomContext<{
  payload: HomePagePayload;
}>;

export type TGtmData = {
  clickAction?: GAClickAction;
  clickLabel?: ISSUE_GA_CLICK_LABEL;
  issue_pub_date?: string;
  chapter1: string;
  pageName: string;
};

function getOnboardingKey(key: string): string {
  return `${key}_visited`;
}

/**
 * this function is used to check the user is visiting first time or not.
 * when the value of the key is null it represents user visiting for the first time
 */
function getShouldShowOnboarding(
  publicationId: PublicationShortname,
  secondaryPublicationId: PublicationShortname | null,
): boolean {
  if (secondaryPublicationId) {
    return (
      localStorage.getItem(getOnboardingKey(publicationId)) == null &&
      localStorage.getItem(getOnboardingKey(secondaryPublicationId)) == null
    );
  } else {
    return localStorage.getItem(getOnboardingKey(publicationId)) == null;
  }
}

type Props = {
  lang: Language;
  secondaryPublicationId?: PublicationShortname | null;
  navTabBarIndex?: number | null;
};

function HomePage({
  lang,
  secondaryPublicationId = null,
  navTabBarIndex = null,
}: Props): React.ReactElement {
  const { i18n } = useTranslation();
  const { context }: RouteContext = useRouteContext();
  const { visitorContext, isLighBoxLoaded, dispatch } = useContext(AppContext);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTileView, setTileView] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [issues, setIssues] = useState<TArchive[]>([]);
  const [calendarData, setCalendarData] =
    useState<HomePageContextType["calendarData"]>(null);
  const [isDesktopView, setIsDesktopView] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(false);

  const publicationId = useMemo<PublicationShortname | null>(
    () => assertEnum(PublicationShortname, context.payload.pubId),
    [context.payload.pubId],
  );

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const gtmDispatch = useEpGTMDispatch();

  useEffect(() => {
    if (publicationId != null) {
      dispatch({
        type: Actions.SET_PUBID,
        payload: { pubId: publicationId },
      });
      Cookies.set(COOKIE_KEY_PUBS_ID, publicationId);
    }
  }, [publicationId]);

  useEffect(() => {
    // verify user on lightbox load and on change in visitorContext
    getUserAuthState();
  }, [isLighBoxLoaded, visitorContext?.visitorId]);

  useEffect(() => {
    if (
      visitorContext !== null &&
      !NO_AUTH_PUBLICATION.includes(publicationId)
    ) {
      handleGAEvent(EP_GA_EVENT_NAME.CUSTOM, {
        clickAction: GAClickAction.LOGIN,
        clickLabel: getGAAuthenticationStatus(visitorContext.visitorCategory),
        issue_pub_date: getTrackerDateString(selectedDate),
        chapter1: PAGE_NAME.PORTAL,
        pageName: PAGE_NAME.PORTAL,
      });
    }
  }, [visitorContext?.visitorId, publicationId]);

  useEffect(() => {
    void (async () => {
      try {
        Cookies.set(COOKIE_KEY_PUBS_ID, publicationId);
        handleGAEvent(EP_GA_EVENT_NAME.PAGE_VIEW, {
          issue_pub_date: getTrackerDateString(selectedDate),
          chapter1: PAGE_NAME.PORTAL,
          pageName: PAGE_NAME.PORTAL,
        });
        setIsLoading(true);
        await fetchApi();
      } catch (e) {
        console.error(e);
      }
    })();
  }, [publicationId]);

  useEffect(() => {
    if (getShouldShowOnboarding(publicationId, secondaryPublicationId)) {
      openModal();
    }
  }, [publicationId]);

  useEffect(() => {
    if (issues.length > 0) {
      setIsLoading(false);
    }
  }, [issues]);

  async function getUserAuthState() {
    // Note: hasUserCredentials not working as expected. We do a workaround to fix this.
    try {
      const isAuthenticated = await MySphLightboxHelper.hasUserCredentials({
        requireAll: false,
      });

      // Workaround: If the initial authentication check fails, we check the visitor context
      // If visitorContext exists and visitorId is a non-empty string, consider the user authenticated
      // This ensures we can display the logout button if the visitor is identified
      if (
        isAuthenticated ||
        (typeof visitorContext?.visitorId === "string" &&
          visitorContext.visitorId.trim() !== "")
      ) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error("Error checking user credentials:", error);
    }
  }

  useEffect(() => {
    if (calendarData) {
      const selectedIssue =
        calendarData[dayjs(selectedDate).format(YMD_FORMAT)];

      if (selectedIssue && selectedIssue.length > 0) {
        setIssues(selectedIssue);
      } else {
        const latestAvailableDate = Object.keys(calendarData)
          .reverse() // Reverse the keys array to start from the latest date
          .find((date) => calendarData[date].length > 0);

        if (latestAvailableDate) {
          setSelectedDate(dayjs(latestAvailableDate).toDate());
          setIssues(calendarData[latestAvailableDate]);
        }
      }
    }
  }, [calendarData, selectedDate]);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktopView(window.innerWidth >= MIN_DESKTOP_WIDTH);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchApi = async () => {
    try {
      if (publicationId !== PublicationShortname.DEFAULT) {
        const data = await fetchArchives(publicationId);

        if (data.issues == null) {
          throw new Error("Issues is null");
        }

        const dataToDisplay: Record<string, TIssue[]> = {};

        for (const issue of data.issues) {
          const pointerDate = dayjs(issue.publishedDate).format(YMD_FORMAT);

          if (dataToDisplay[pointerDate]) {
            dataToDisplay[pointerDate].push(issue);
          } else {
            dataToDisplay[pointerDate] = [issue];
          }
        }

        setCalendarData(dataToDisplay);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const toggleDrawer = () => {
    setShowDrawer((prevState) => {
      if (!prevState && visitorContext !== null) {
        handleGAEvent(EP_GA_EVENT_NAME.CUSTOM, {
          clickAction: GAClickAction.CLICK,
          clickLabel: ISSUE_GA_CLICK_LABEL.CALENDAR,
          issue_pub_date: getTrackerDateString(selectedDate),
          chapter1: PAGE_NAME.PORTAL,
          pageName: PAGE_NAME.PORTAL,
        });
      }

      return !prevState;
    });
  };

  const openModal = () => {
    setShowModal(true);

    // to call custom GA event on info button click
    if (!getShouldShowOnboarding(publicationId, secondaryPublicationId)) {
      handleGAEvent(EP_GA_EVENT_NAME.CUSTOM, {
        clickAction: GAClickAction.CLICK,
        clickLabel: ISSUE_GA_CLICK_LABEL.INFO_BUTTON,
        issue_pub_date: getTrackerDateString(selectedDate),
        chapter1: PAGE_NAME.PORTAL,
        pageName: PAGE_NAME.PORTAL,
      });
    }
    // to call page view GA when modal is visible
    handleGAEvent(EP_GA_EVENT_NAME.PAGE_VIEW, {
      chapter1: PAGE_NAME.ONBOARDING,
      pageName: PAGE_NAME.ONBOARDING,
    });
  };

  const closeModal = () => {
    setShowModal(false);

    if (getShouldShowOnboarding(publicationId, secondaryPublicationId)) {
      if (secondaryPublicationId) {
        localStorage.setItem(getOnboardingKey(secondaryPublicationId), "true");
      }
      localStorage.setItem(getOnboardingKey(publicationId), "true");
    }

    handleGAEvent(EP_GA_EVENT_NAME.CUSTOM, {
      clickAction: GAClickAction.CLOSE,
      clickLabel: ISSUE_GA_CLICK_LABEL.CLOSE_ONBOARDING_MODAL,
      chapter1: PAGE_NAME.ONBOARDING,
      pageName: PAGE_NAME.ONBOARDING,
    });
  };

  const handleIssueRedirect =
    (source: ISSUE_GA_CLICK_LABEL, issue: Partial<TArchive>) => async () => {
      try {
        if (issue.id == null) {
          throw new Error("Issue ID is null, cannot retrieve reader URL.");
        }

        const resp = await getIssueById({
          pubId: publicationId,
          issueId: issue.id,
        });

        if (resp == null || typeof resp.readerUrlWithPubs !== "string") {
          throw new Error(
            `Reader URL cannot be found :: ${JSON.stringify(resp)}`,
          );
        }

        Cookies.set(COOKIE_ISSUE_ID, issue.id.toString());
        const readerUrl = new URL(resp.readerUrlWithPubs);

        handleGAEvent(EP_GA_EVENT_NAME.CUSTOM, {
          clickAction: GAClickAction.CLICK,
          clickLabel: source,
          issue_pub_date: getTrackerDateString(selectedDate),
          chapter1: PAGE_NAME.PORTAL,
          pageName: PAGE_NAME.PORTAL,
        });

        if (NO_AUTH_PUBLICATION.includes(publicationId)) {
          window.location.href = readerUrl.toString();
          return;
        }

        if (await MySphLightboxHelper.hasUserCredentials()) {
          // how to check if the credentials is belongs to which publication
          console.debug("User has credentials, redirecting to reader URL");
          window.location.href = readerUrl.toString();
          return;
        }

        console.debug("User does not have credentials, redirecting to auth");

        const mysphSvc = assertEnum(PublicationShortname, publicationId);

        if (mysphSvc == null) {
          throw new Error("invalid pubId");
        }

        saveCookiePath();
        const authOptions = {
          postLoginUrl: readerUrl.toString(),
          lan: i18n.language, // pubs localization here
          svc: getServiceFlagsByPubsId(mysphSvc),
        };
        MySphLightboxHelper.startRedirectAuth(authOptions);
      } catch (error) {
        // pop up if needed
        console.error(error);
      }
    };

  const handleGAEvent = (eventName: EP_GA_EVENT_NAME, gtmData?: TGtmData) => {
    if (visitorContext !== null) {
      const GAContext = NO_AUTH_PUBLICATION.includes(publicationId)
        ? DEFAULT_VISITOR_CONTEXT
        : visitorContext;

      gtmDispatch({
        eventName: eventName,
        eventDetail: {
          ...gtmData,
          visitorCategory: GAContext.visitorCategory,
          visitorId: GAContext.visitorId,
          svc: GAContext.svc,
          orientation: getScreenOrientation(),
          publication_name: getGAPublicationName(publicationId),
        },
      });
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    //clear visitor context from context on log out
    dispatch({
      type: Actions.SET_VIEWERCONTEXT,
      payload: { visitorContext: DEFAULT_VISITOR_CONTEXT },
    });
  };

  const toggleTileView = () => {
    setTileView((preState) => !preState);
  };

  return (
    <div className={cx(styles.homeWrapper)}>
      <HomePageContext.Provider
        value={{
          toggleDrawer,
          setSelectedDate,
          calendarData,
          isDesktopView,
          selectedDate,
          issues,
        }}
      >
        <Header
          publicationId={publicationId}
          handleLogout={handleLogout}
          isLoggedIn={isLoggedIn}
          openModal={openModal}
          toggleDrawer={toggleDrawer}
          isLoading={isLoading}
          toggleTileView={toggleTileView}
          showTileView={showTileView}
        />
        {secondaryPublicationId && publicationId && (
          <MultiTitle
            secondaryPublicationId={secondaryPublicationId}
            publicationId={publicationId}
            navTabBarIndex={navTabBarIndex}
          />
        )}
        <div className={cx(styles.homeContainer)}>
          <div
            className={cx([
              styles.readerViewContainer,
              showTileView && styles.hideReaderView,
            ])}
          >
            <div className={cx(styles.testContainer)}>
              <div
                className={cx(styles.dateContainer)}
                data-testid="dateContainer"
              >
                <div className={cx(styles.dayTxt)} data-theme={publicationId}>
                  {selectedDate &&
                    useDateFormat({
                      date: selectedDate,
                      shouldShowDayOfWeek: true,
                    })}
                </div>
                <div className={cx(styles.dateTxt)} data-theme={publicationId}>
                  {useDateFormat({ date: selectedDate })}
                </div>
              </div>
              {isLoading ? (
                <div className={cx(styles.pubsRow)}>
                  <IssueContainer
                    theme={publicationId}
                    showTileView={showTileView}
                  />
                </div>
              ) : (
                <div className={cx(styles.pubsRow)}>
                  {issues.length > 0 && (
                    <IssueContainer
                      theme={publicationId}
                      showTileView={showTileView}
                      imageSrc={issues[0].coverImageUri}
                      handleButtonClick={handleIssueRedirect(
                        ISSUE_GA_CLICK_LABEL.START_READING_BTN,
                        issues[0],
                      )}
                      handleImageClick={handleIssueRedirect(
                        ISSUE_GA_CLICK_LABEL.IMAGE_CLICK,
                        issues[0],
                      )}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          {showTileView && (
            <div className={cx(styles.tileViewWrapper)}>
              <TileView
                issues={calendarData}
                theme={publicationId}
                toggleTileView={toggleTileView}
              />
            </div>
          )}
        </div>
        {showModal ? (
          <OnboardingModal onClose={closeModal} handleGAEvent={handleGAEvent} />
        ) : null}
        {showDrawer ? (
          <DrawerContainer
            showDrawer={showDrawer}
            isMobileView={!isDesktopView}
          />
        ) : null}
      </HomePageContext.Provider>
    </div>
  );
}

export default HomePage;
