import { ReactElement, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLanguageByPubId } from "src/app/auth/serviceIdentifier";

import logo from "../../assets/SPH24-Logo.svg";
import errorStatusIcon from "../../assets/error-status-icon.svg";

import styles from "./ErrorPage.module.scss";
import AppContext from "src/app/AppContext";

export default function ErrorPage(): ReactElement {
  const { i18n, t } = useTranslation();
  const [okayRoute, setOkayRoute] = useState<string | null>(null);
  const [lang, setLang] = useState<string>("en");
  const { pubId } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (pubId) {
      setOkayRoute(`/${pubId}`);
      setLang(getLanguageByPubId(pubId));
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const handleNotNow = () => {
    if (okayRoute) {
      navigate(okayRoute);
    }
  };

  return (
    <div className={styles.errorPage}>
      <div className={styles.errorPageContainer}>
        <div className={styles.errorPageContainerHeader}>
          <div className={styles.logo}>
            <img src={logo} alt="Logo" />
          </div>
        </div>
        <div className={styles.errorPageContainerStatusIcon}>
          <img src={errorStatusIcon} alt="Error icon" />
        </div>
        <div className={styles.errorPageContainerTitle}>{t("error:title")}</div>
        <div className={styles.errorPageContainerDescription}>
          <p>{t("error:body.line1")}</p>
          <p>{t("error:body.line2")}</p>
        </div>
        <div className={styles.errorPageContainerOkayButton}>
          <button onClick={handleNotNow}>{t("error:cta")}</button>
        </div>
      </div>
    </div>
  );
}
