import cx from "classnames";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { PublicationShortname } from "src/app/auth/serviceIdentifier";
import useDateFormat from "src/app/hooks/useDateFormat";
import { HomePageContext } from "src/app/pages/Home";
import { TArchive } from "src/app/pages/Home/api";

import IssueLoader from "../IssueLoader";
import styles from "./index.module.scss";

type Props = {
  issues: Record<string, TArchive[]> | null;
  theme: PublicationShortname | null;
  toggleTileView: () => void;
};

type TileProps = {
  publishedDate: string | undefined;
  coverImageUri: string | undefined;
  theme: PublicationShortname | null;
  toggleTileView: () => void;
};

//component for single issue tile
const Tile = ({
  publishedDate,
  toggleTileView,
  coverImageUri,
  theme,
}: TileProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const { setSelectedDate } = useContext(HomePageContext);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    if (isLoaded) {
      setIsLoaded(false);
    }
  }, [theme]);

  return (
    <button
      key={publishedDate}
      onClick={() => {
        setSelectedDate(publishedDate);
        toggleTileView();
      }}
    >
      <IssueLoader
        forTileView={true}
        imageSrc={coverImageUri}
        isLoaded={isLoaded}
        handleImageLoad={handleImageLoad}
      />
      <p data-theme={theme} className={cx(styles.tileDay)}>
        {useDateFormat({
          date: publishedDate,
          shouldShowDayOfWeek: true,
        })}
      </p>
      <p data-theme={theme} className={cx(styles.tileDate)}>
        {useDateFormat({
          date: publishedDate,
        })}
      </p>
    </button>
  );
};

function TileView({ issues, theme, toggleTileView }: Props) {
  const issueLoader: number[] = useMemo(
    () => Array.from({ length: 14 }, (_, i) => i),
    [],
  );

  return (
    <div className={cx(styles.tileViewContainer)}>
      <div className={cx(styles.tileView)}>
        {issues
          ? Object.keys(issues)
              .reverse()
              .map((issue: string) => {
                return (
                  <div
                    key={issues[issue][0]?.publishedDate}
                    className={cx(styles.issueTile)}
                  >
                    <Tile
                      publishedDate={issues[issue][0].publishedDate}
                      coverImageUri={issues[issue][0].coverImageUri}
                      theme={theme}
                      toggleTileView={toggleTileView}
                    />
                  </div>
                );
              })
          : issueLoader.map((index) => (
              <div key={index} className={cx(styles.issueTile)}>
                <IssueLoader forTileView={true} />
              </div>
            ))}
      </div>
    </div>
  );
}

export default TileView;
