import { AuthCallback, LogoutCallback } from "@sphtech/web2-core/auth";
import { fetchContext } from "@sphtech/web2-core/ssr";
import { RouteObject } from "react-router-dom";
import HomePage from "src/app/pages/Home";

import {
  getClientId,
  getIssuer,
} from "../../.web2/web2-helpers/auth/MySphConfig";
import App from "./App";
import { getCookiePath } from "./auth/cookiePath";
import ConcurrencyPage from "./pages/Concurrency";
import CorpCallbackPage from "./pages/CorpCallback";
import FallbackPage from "./pages/Fallback";
import SubscribePage from "./pages/SubscribePage";
import UnauthenticatedPage from "./pages/Unauthenticated";
import { Language } from "./types/Data.d";
import ErrorPage from "./pages/ErrorPage";
import { PublicationShortname } from "./auth/serviceIdentifier";

export const reactRouterRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        index: true,
        element: <FallbackPage />,
      },
      /**
       * MySPH Integration guide: https://sph.atlassian.net/wiki/x/V4GqY
       */
      {
        path: "/oauth/callback",
        element: (
          <AuthCallback
            clientId={getClientId()}
            issuer={getIssuer()}
            getCookiePath={getCookiePath}
          />
        ),
      },
      {
        path: "/oauth/logout",
        element: <LogoutCallback />,
      },
      {
        path: "/st",
        element: <HomePage lang={Language.en} />,
        loader: fetchContext,
      },
      {
        path: "/bt",
        element: <HomePage lang={Language.en} />,
        loader: fetchContext,
      },
      {
        path: "/zb",
        element: (
          <HomePage
            lang={Language.zh}
            secondaryPublicationId={PublicationShortname.SM}
            navTabBarIndex={1}
          />
        ),
        loader: fetchContext,
      },
      {
        path: "/bh",
        element: <HomePage lang={Language.ms} />,
        loader: fetchContext,
      },
      {
        path: "/tm",
        element: (
          <HomePage
            lang={Language.ta}
            secondaryPublicationId={PublicationShortname.TABLA}
            navTabBarIndex={1}
          />
        ),
        loader: fetchContext,
      },
      {
        path: "/tabla",
        element: (
          <HomePage
            lang={Language.en}
            secondaryPublicationId={PublicationShortname.TM}
            navTabBarIndex={2}
          />
        ),
        loader: fetchContext,
      },
      {
        path: "/sm",
        element: (
          <HomePage
            lang={Language.zh}
            secondaryPublicationId={PublicationShortname.ZB}
            navTabBarIndex={2}
          />
        ),
        loader: fetchContext,
      },
      {
        path: "/unauthenticated",
        element: <UnauthenticatedPage />,
      },
      {
        path: "/subscribe",
        element: <SubscribePage />,
      },
      {
        path: "/concurrency",
        element: <ConcurrencyPage />,
      },
      {
        path: "/error",
        element: <ErrorPage />,
      },
      {
        path: "/corpCallback/:svc",
        element: <CorpCallbackPage />,
        loader: fetchContext,
      },
      { path: "*", element: <FallbackPage /> },
    ],
  },
];

export const routes = reactRouterRoutes.reduce(
  (_routes: string[], currentRoute: RouteObject) => {
    if (currentRoute.path) {
      _routes = [..._routes, currentRoute.path];
    }

    if (currentRoute.children) {
      for (const child of currentRoute.children) {
        if (child.path) {
          _routes = [..._routes, child.path];
        }
      }
    }

    return _routes;
  },
  [],
);
