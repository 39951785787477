import cx from "classnames";
import icClose from "src/app/assets/icons/icon-close.svg";

import styles from "./index.module.scss";

type MobileCloseBtnProps = {
  theme?: string;
  onClick: () => void;
};

export const MobileCloseBtn = ({ theme, onClick }: MobileCloseBtnProps) => {
  return (
    <div
      className={cx(styles.closeBtn)}
      onClick={onClick}
      role="button"
      data-theme={theme}
    >
      <img src={icClose} alt="close_icon" />
      <span>Close</span>
    </div>
  );
};
