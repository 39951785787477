import cx from "classnames";
import React, { useContext, useState } from "react";

import { HomePageContext } from "../../pages/Home";
import { BottomContainer } from "../BottomContainer";
import { CalendarContainer } from "../CalendarContainer";
import { MobileCloseBtn } from "../MobileCloseBtn";
import { TopContainer } from "../TopContainer";
import styles from "./index.module.scss";

type DrawerContainerProps = {
  isMobileView: boolean;
  theme?: string;
  showDrawer: boolean;
};
type DrawerPageContextType = {
  setHideDrawer: (_: boolean) => void;
  hideDrawer: boolean;
  swipeRight?: boolean;
  setSwipeRight: (_: boolean | undefined) => void;
  enterLeft?: boolean;
  setEnterLeft: (_: boolean | undefined) => void;
};
export const DrawerPageContext = React.createContext<DrawerPageContextType>({
  setHideDrawer: () => {},
  hideDrawer: false,
  swipeRight: undefined,
  setSwipeRight: () => {},
  enterLeft: undefined,
  setEnterLeft: () => {},
});

export const DrawerContainer = ({
  isMobileView,
  showDrawer,
  theme,
}: DrawerContainerProps) => {
  const [hideDrawer, setHideDrawer] = useState<boolean>(false);
  const [swipeRight, setSwipeRight] = useState<boolean | undefined>(undefined);
  const [enterLeft, setEnterLeft] = useState<boolean | undefined>(undefined);
  const { toggleDrawer } = useContext(HomePageContext);

  const closeDrawer = () => {
    setHideDrawer(true);
    setTimeout(function () {
      toggleDrawer();
    }, 500);
  };

  return (
    <DrawerPageContext.Provider
      value={{
        setHideDrawer,
        hideDrawer,
        swipeRight,
        setSwipeRight,
        enterLeft,
        setEnterLeft,
      }}
    >
      <div
        className={cx({
          [styles.drawerContainer]: true,
          [styles.show]: showDrawer,
          [styles.hide]: hideDrawer,
        })}
        data-testid="drawer"
        data-theme={theme}
      >
        {!isMobileView && <TopContainer />}
        <CalendarContainer />
        <BottomContainer />
        {isMobileView && <MobileCloseBtn onClick={closeDrawer} />}
      </div>
    </DrawerPageContext.Provider>
  );
};
