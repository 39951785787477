import cx from "classnames";
import { useContext } from "react";

import { HomePageContext } from "../../pages/Home";
import { DateTable } from "../DateTable";
import { DayRow } from "../DayRow";
import styles from "./index.module.scss";

type CalendarContainerProps = {
  theme?: string;
};

export const CalendarContainer = ({ theme }: CalendarContainerProps) => {
  const { calendarData, selectedDate } = useContext(HomePageContext);
  return (
    <div className={cx(styles.calendarContainer)} data-theme={theme}>
      <DayRow />
      <DateTable calendarData={calendarData} selectedDate={selectedDate} />
    </div>
  );
};
