import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import cx from "classnames";
import { ReactElement, useState } from "react";
import Slider from "react-slick";
import articleViewImg from "src/app/assets/article-view.gif";
import pastIssueImg from "src/app/assets/past-issue.gif";
import quickAccessImg from "src/app/assets/quick-access.gif";
import { TGtmData } from "src/app/pages/Home";
import { EP_GA_EVENT_NAME, PAGE_NAME } from "src/trackers/googleAnalytics";

import { Modal } from "../Modal";
import CarouselButton, { CarouselButtonTheme } from "./CarouselButton";
import styles from "./index.module.scss";
import { OnboardingSlide } from "./OnboardingSlide";
import { ShinyPhoneImage } from "./ShinyPhoneImage";

const onBoardingImages: string[] = [
  articleViewImg,
  pastIssueImg,
  quickAccessImg,
];

type Props = {
  onClose: () => void;
  handleGAEvent: (eventName: EP_GA_EVENT_NAME, gtmData: TGtmData) => void;
};

export function OnboardingModal({
  onClose,
  handleGAEvent,
}: Props): ReactElement {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => {
      // to call page view GA event on forward button click
      if (currentSlide < next) {
        handleGAEvent(EP_GA_EVENT_NAME.PAGE_VIEW, {
          chapter1: PAGE_NAME.ONBOARDING,
          pageName: PAGE_NAME.ONBOARDING,
        });
      }

      setCurrentSlide(next);
    },
    nextArrow:
      currentSlide >= 3 ? (
        <CarouselButton
          theme={CarouselButtonTheme.CLOSE}
          alt="done tick image"
          onClose={onClose}
        />
      ) : (
        <CarouselButton
          theme={CarouselButtonTheme.NEXT}
          alt="next arrow image"
        />
      ),
    prevArrow: (
      <CarouselButton
        theme={CarouselButtonTheme.BACK}
        alt="back arrow image"
        isDisabled={currentSlide === 0}
      />
    ),
  };

  return (
    <Modal onClose={onClose}>
      <div className={cx(styles.onBoardingModalBody)}>
        <Slider {...settings}>
          <OnboardingSlide slideNumber={0}>
            <ShinyPhoneImage />
          </OnboardingSlide>
          {onBoardingImages.map((data: string, index: number) => (
            <OnboardingSlide slideNumber={index + 1} key={index}>
              <img
                src={data}
                alt="Onboarding"
                className={cx(styles.imgShadow)}
              />
            </OnboardingSlide>
          ))}
        </Slider>
      </div>
    </Modal>
  );
}
