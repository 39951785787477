import cx from "classnames";
import { ReactElement } from "react";

import phoneImage from "/src/app/assets/phone.gif";
import shineImage from "/src/app/assets/shine.gif";

import styles from "./index.module.scss";

export function ShinyPhoneImage(): ReactElement {
  return (
    <svg className={cx(styles.phoneImgWrapper)}>
      <image className={cx(styles.phoneImg)} href={phoneImage} />
      <image href={shineImage} />
    </svg>
  );
}
