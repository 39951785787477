import cx from "classnames";

import styles from "./index.module.scss";

type DayIcnProps = {
  day: string;
  index: number;
  theme?: string;
};

export const DayIcn = ({ day, index, theme }: DayIcnProps) => {
  return (
    <div key={`day-${index}`} className={cx(styles.dayTxt)} data-theme={theme}>
      {day}
    </div>
  );
};
