import cx from "classnames";
import dayjs from "dayjs";

import styles from "./index.module.scss";

type DateIcnProps = {
  isSelected: boolean;
  isDisabled: boolean;
  date: Date;
  theme?: string;
  onClick: () => void;
};

export const DateIcn = ({
  isSelected = false,
  isDisabled = true,
  date,
  theme,
  onClick,
}: DateIcnProps) => {
  return (
    <button
      key={dayjs(date).format("YYYYMMDD")}
      className={cx(styles["date__container"])}
      onClick={onClick}
      data-theme={theme}
    >
      <div
        className={cx({
          [styles["date__inner--default"]]: true,
          [styles["date__inner--selected"]]: isSelected,
          [styles["date__inner--invalid"]]: isDisabled,
        })}
      >
        {dayjs(date).format("DD")}
      </div>
    </button>
  );
};
