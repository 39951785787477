import cx from "classnames";

import { DayIcn } from "../DayIcn";
import styles from "./index.module.scss";

type DayRowProps = {
  theme?: string;
};

export const DayRow = ({ theme }: DayRowProps) => {
  const days = ["M", "T", "W", "T", "F", "S", "S"];

  return (
    <div className={cx(styles.dayRow)} data-theme={theme}>
      {days.map((day, index) => {
        return (
          <DayIcn key={`${day}-${index}`} day={day} index={index}></DayIcn>
        );
      })}
    </div>
  );
};
