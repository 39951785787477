import cx from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import ShimmerLoading from "src/app/assets/shimmer-loading.json";
import { PublicationShortname } from "src/app/auth/serviceIdentifier";

import IssueLoader from "../IssueLoader";
import Tooltip from "../Tooltip";
import styles from "./index.module.scss";

type Props = {
  imageSrc?: string | undefined;
  theme: PublicationShortname | null;
  key?: number;
  handleButtonClick?: () => void;
  handleImageClick?: () => void;
  showTileView: boolean;
};

const IssueContainer = ({
  key,
  theme,
  imageSrc,
  handleButtonClick,
  handleImageClick,
  showTileView,
}: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { t } = useTranslation();

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    setIsLoaded(false);
  }, [theme]);

  return (
    <div key={key} className={cx(styles["content-wrapper"])}>
      <div
        className={`${cx(styles["content"])} ${showTileView && cx(styles.withTileView)} `}
      >
        <IssueLoader
          imageSrc={imageSrc}
          handleImageClick={handleImageClick}
          handleImageLoad={handleImageLoad}
          isLoaded={isLoaded}
        />
      </div>
      <Tooltip
        text={t("home:tooltip.StartReading")}
        position="top"
        theme={theme}
      >
        <button
          className={cx(styles["content__btn-read"])}
          data-theme={theme}
          onClick={handleButtonClick}
          disabled={!isLoaded}
        >
          {t("home:actions.StartReading")}
        </button>
      </Tooltip>
    </div>
  );
};

export default IssueContainer;
