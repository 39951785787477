import cx from "classnames";
import React from "react";
import Lottie from "react-lottie";
import ShimmerLoading from "src/app/assets/shimmer-loading.json";

import styles from "./index.module.scss";

type Props = {
  imageSrc?: string | undefined;
  forTileView?: boolean;
  isLoaded?: boolean;
  handleImageClick?: () => void;
  handleImageLoad?: () => void;
};

function IssueLoader({
  imageSrc,
  handleImageLoad,
  handleImageClick,
  forTileView,
  isLoaded,
}: Props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ShimmerLoading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={cx(styles.placeHolderWrapper)}>
      <div
        className={
          forTileView ? cx(styles.tileImageWrapper) : cx(styles.imageWrapper)
        }
      >
        {!isLoaded && (
          <div className={cx(styles["shimmer-image"])}>
            <Lottie options={defaultOptions} />
          </div>
        )}
        <img
          src={imageSrc}
          alt="issue-img"
          data-testid="issue-img"
          onLoad={handleImageLoad}
          onClick={handleImageClick}
          style={{
            display: isLoaded ? "block" : "none",
          }}
        />
      </div>
    </div>
  );
}

export default IssueLoader;
