import { MySphLightboxHelper } from "@sphtech/web2-core/auth";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import Cookies from "js-cookie";
import { ReactElement, useEffect } from "react";
import { COOKIE_PATH_KEY } from "src/app/auth/cookiePath";
import {
  getLanguageByPubId,
  getPubIdBySvc,
} from "src/app/auth/serviceIdentifier";
import { DefaultLightboxDelay } from "src/app/utils";

import { COOKIE_KEY_PUBS_ID } from "../Home";
import { CorpCallbackPagePayload } from "./index.server";

type RouteContext = CustomContext<{
  payload: CorpCallbackPagePayload;
}>;

export default function CorpCallbackPage(): ReactElement {
  const { context }: RouteContext = useRouteContext();

  useEffect(() => {
    async function startOIDCAuth() {
      try {
        /**
         * In order to ensure that the lightbox script has loaded, we need to
         * ensure that we run some async code before any error is possibly thrown.
         *
         * We set a default wait on standalone pages that uses lightbox
         * as tested, without this lightbox is not initialised.
         *
         */
        await new Promise((res) =>
          setTimeout(() => {
            res(() => {});
          }, DefaultLightboxDelay),
        );

        const svc = context.payload.svc;

        if (!svc) {
          throw new Error("SVC parameter is missing");
        }

        const pubId = getPubIdBySvc(svc);
        if (!pubId) {
          throw new Error(`Invalid PubID: ${pubId}`);
        }
        Cookies.set(COOKIE_KEY_PUBS_ID, pubId);
        localStorage.setItem(COOKIE_PATH_KEY, `/${pubId}`);
        const lan = getLanguageByPubId(pubId);

        MySphLightboxHelper.startRedirectAuth({
          postLoginUrl: `https://${window.location.host}/${pubId}`,
          lan,
          svc,
        });
      } catch (error: unknown) {
        console.error("Failed to start OIDC redirect auth.", error);
      }
    }
    void startOIDCAuth();
  }, []);

  return <div />;
}
